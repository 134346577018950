import React from "react";
import "./style.css"; // We'll define our styles here

export const Big = () => {
  return (
    <div className="big">
      <header className="header">
        <div className="container">
          <div className="logo">$long</div>
          <div className="social-links">
            <a href="https://x.com/home" className="social-link">
              <img alt="Twitter" src="img/twitter.svg" />
            </a>
            <a href="https://t.me" className="social-link">
              <img alt="Telegram" src="img/telegram.svg" />
            </a>
          </div>
        </div>
      </header>

      <main className="main">
        <section className="hero">
          <img className="hero-image" alt="Container" src="/img/container.svg" />
          <div className="hero-content">
            <h1 className="hero-title">long</h1>
            <p className="hero-description">
              hes a dog, and hes long. and since you're long on sol, you're long on $long, right?
            </p>
            <button className="cta-button">BUY NOW</button>
          </div>
        </section>

        <div className="ticker">
          {Array(11).fill().map((_, i) => (
            <div key={i} className="ticker-item">$LONG</div>
          ))}
        </div>

        <nav className="nav">
          <ul className="nav-list">
            {["TWITTER", "TELEGRAM", "DEXTOOLS", "PUMP.FUN"].map((text, i) => (
              <li key={i} className="nav-item">{text}</li>
            ))}
          </ul>
        </nav>

        <section className="tokenomics">
          <h2 className="section-title">Tokenomics</h2>
          <div className="separator">??????????????????????????????????</div>
        </section>

        <div className="features">
          {["img/container-1.svg", "img/container-2.svg", "img/container-3.svg"].map((src, i) => (
            <div key={i} className="feature-card">
              <img className="feature-image" alt={`Feature ${i + 1}`} src={src} />
              <div className="feature-title">
                {["TAX: 0%", "MINT: REVOKED", "LP: BURNT"][i]}
              </div>
            </div>
          ))}
        </div>
      </main>

      <footer className="footer">
        <p className="footer-text">with love - the $long money crew</p>
      </footer>
    </div>
  );
};